import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { accessToken, AccessTokenParams, TokenResponse } from "queries/auth";
import {
  registration,
  RegisterParams,
  DetailRegistrationItem,
  ListUserItem,
  USER_TYPE,
  detailUser,
} from "queries/users";
import { DecodedToken, decode, getTokens, removeTokens, setTokens } from "utils/token";
import ROUTES from "routes";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";

type AuthContextProps = {
  currentUser?: ListUserItem;
  login: UseMutateFunction<TokenResponse, unknown, AccessTokenParams, unknown>;
  registerUser: UseMutateFunction<TokenResponse, unknown, RegisterParams, unknown>;
  tokens: { access?: DecodedToken; refresh?: DecodedToken };
  isLoggingIn: boolean;
  isRegistrationInProgress: boolean;
  // User is logged in if access token or refresh token is present.
  // (doesn't mean we already have any user info)
  // Should be correctly set already in initial render.
  isLoggedIn: boolean;
  logout: () => void;
  isAdmin: boolean;
  authError: AxiosError | null;
  registerError: AxiosError | null;
};

const INITIAL_VALUES: AuthContextProps = {
  currentUser: undefined,
  login: () => {},
  registerUser: () => {},
  tokens: {},
  isLoggingIn: false,
  isRegistrationInProgress: false,
  isLoggedIn: false,
  logout: () => {},
  isAdmin: false,
  authError: null,
  registerError: null,
};

const AuthContext = React.createContext<AuthContextProps>(INITIAL_VALUES);

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // login (get access and refresh tokens)
  const loginMutation = useMutation<TokenResponse, AxiosError, AccessTokenParams, unknown>({
    mutationFn: accessToken,
    onSuccess: (res) => {
      const access = decode(res.access);
      // if REACT_APP_ALLOW_ALL is true -> then allow all users to access the app, otherwise only admin users
      if (process.env.REACT_APP_ALLOW_ALL || access.payload?.user.type === USER_TYPE.ADMIN) {
        setTokens(res);

        // enqueue notification
        const from = location.state?.from?.pathname || ROUTES.HOME;
        navigate(from, { replace: true });
      }
    },
    onError: (e: AxiosError) => {
      Sentry.captureException(e);
    },
  });

  const registerUserMutation = useMutation<
    DetailRegistrationItem,
    AxiosError,
    RegisterParams,
    unknown
  >({
    mutationFn: registration,
    onSuccess: (res, variables) => {
      // After successful registration, attempt to log in
      loginMutation.mutate({
        username: res.username,
        password: variables.password, // Use the password from the original request
      });
    },
    onError: (e: AxiosError) => {
      Sentry.captureException(e);
    },
  });

  const logout = React.useCallback(() => {
    removeTokens();
    if ("caches" in window) {
      const clearIt = async () => {
        const cacheKeys = await window.caches.keys();
        await Promise.all(
          cacheKeys.map((key) => {
            return window.caches.delete(key);
          })
        );
      };
      clearIt();
    }
    localStorage.clear();
    navigate(ROUTES.LOGIN, { replace: true });
  }, [navigate]);

  const { access, refresh } = getTokens();

  // pack decoded tokens into object
  const tokens = React.useMemo(() => {
    const tokens: AuthContextProps["tokens"] = {};

    if (access) tokens.access = decode(access);
    if (refresh) tokens.refresh = decode(refresh);
    return tokens;
  }, [access, refresh]);

  const userId = React.useMemo(
    () => tokens.access?.payload?.user_id,
    [tokens.access?.payload?.user_id]
  );

  // get user details
  const { data: currentUser, error: authError } = useQuery<ListUserItem, AxiosError>({
    queryKey: ["detail-user", { id: userId }],
    queryFn: () => detailUser({ id: userId as string }),
    enabled: !!userId,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  // Logout user if we are unable to get user details
  React.useEffect(() => {
    if (currentUser && authError && authError.response?.status === 401) {
      logout();
    }
  }, [currentUser, authError, logout]);

  // If REACT_APP_ALLOW_ALL is false -> then only admin users can access the app, logout if not admin
  React.useEffect(() => {
    if (!process.env.REACT_APP_ALLOW_ALL && currentUser && currentUser.type !== USER_TYPE.ADMIN) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const value = React.useMemo(
    () => ({
      login: loginMutation.mutate,
      isLoggingIn: loginMutation.isLoading,
      logout,
      // User is logged in if access token or refresh token is present.
      // If we don't have access token, but do have refresh token, we are logged in
      // Access token will auto renew on next request (se interceptor in src/utils/axios.ts)
      isLoggedIn: !!access || !!refresh,
      tokens,
      currentUser,
      isAdmin: tokens.access?.payload?.user.type === USER_TYPE.ADMIN,
      authError: loginMutation.error,
      registerUser: (params: RegisterParams) => {
        registerUserMutation.mutate(params);
      },
      isRegistrationInProgress: registerUserMutation.isLoading || loginMutation.isLoading,
      registerError: registerUserMutation.error,
    }),
    [
      loginMutation.mutate,
      loginMutation.isLoading,
      loginMutation.error,
      registerUserMutation,
      logout,
      access,
      refresh,
      tokens,
      currentUser,
    ]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => React.useContext(AuthContext);
