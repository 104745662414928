import { useValidation } from "context/ValidationProvider";
import React, { useEffect, useState } from "react";
import { Layer, Rect, Stage } from "react-konva";
import { Annotation } from "hooks/useAnnotations";
import { Box, Dialog, DialogContent } from "@mui/material";
import URLImage from "../URLImage";
import Toolbar from "./Toolbar";

const DeletableRect = ({
  annotation: { x, y, width, height, stroke },
  strokeWidth,
  isSelected,
  onClick,
}: {
  annotation: Annotation;
  strokeWidth: number | undefined;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      {/* Hovered or selected background */}
      {(isHovered || isSelected) && (
        <Rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={stroke}
          // hover is much more subtle than selected
          opacity={isSelected ? 0.4 : 0.2}
        />
      )}

      <Rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke={stroke}
        strokeWidth={strokeWidth}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={onClick}
      />
    </>
  );
};

export type ImageEditorProps = {
  data: Blob;
};

const ImageEditor: React.FC<ImageEditorProps> = ({ data }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  // trigger rerender when ref size changes (simplest way to do this)
  // (either when full-screen is toggled or when window is resized)
  const [, forceUpdate] = React.useState<unknown>();

  const [isFullscreen, setIsFullscreen] = React.useState(false);

  // listen on resized and update stage
  useEffect(() => {
    const fn = (e: Event) => {
      forceUpdate(e);
    };
    window.addEventListener("resize", fn);

    return () => {
      window.removeEventListener("resize", fn);
    };
  }, []);

  // listen on fullscreen toggle and update stage
  useEffect(() => {
    forceUpdate({});
  }, [isFullscreen]);

  const {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    annotations,
    newAnnotation,
    stageRef,
    coordinateIndexToDelete,
    setCoordinateIndexToDelete,
  } = useValidation();

  const width = ref.current?.offsetWidth ?? 0;

  // Stroke width is a fixed percentage of image width
  const strokeWidth = width ? width * 0.008 : undefined;

  const content = (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
      ref={ref}
    >
      <Stage
        ref={stageRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        width={width}
        height={300}
        style={{
          cursor: "crosshair",
        }}
      >
        <Layer>
          <URLImage obj={data} />
          {annotations.map((value, index) => {
            return (
              <DeletableRect
                // This is fine as a key as we are only adding and removing the last annotation
                // eslint-disable-next-line react/no-array-index-key
                key={`${value.x}-${value.y}-${value.width}-${value.height}-${index}`}
                annotation={value}
                strokeWidth={strokeWidth}
                isSelected={coordinateIndexToDelete === index}
                onClick={() => {
                  setCoordinateIndexToDelete(index);
                }}
              />
            );
          })}

          {/* Simply draw the new annotation (no hover or selection options) */}
          {newAnnotation && (
            <Rect
              x={newAnnotation.x}
              y={newAnnotation.y}
              width={newAnnotation.width}
              height={newAnnotation.height}
              stroke={newAnnotation.stroke}
              strokeWidth={strokeWidth}
            />
          )}
        </Layer>
      </Stage>
      <Toolbar
        isFullscreen={isFullscreen}
        toggleFullscreen={() => setIsFullscreen(!isFullscreen)}
      />
    </Box>
  );

  if (isFullscreen) {
    // There won't be any fade-in/out animations, but this is a good enough
    // and we don't want to mount the content twice just to fix the animations.
    return (
      <Dialog
        open={isFullscreen}
        PaperProps={{
          style: {
            minHeight: "99%",
            minWidth: "99%",
          },
        }}
      >
        <DialogContent>{content}</DialogContent>
      </Dialog>
    );
  }

  return content;
};

export default ImageEditor;
