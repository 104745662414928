import { useQuery } from "@tanstack/react-query";
import { IMAGE_TYPES, downloadImage } from "queries/devices/images";
import React from "react";
import { SECONDS_30 } from "utils/time";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import ImageReader from "./ImageReader";
import LoadingImage from "./LoadingImage";

type PopupImageProps = {
  childrenAsProp?: JSX.Element;
};

export const PopupImage: React.FC<React.PropsWithChildren<PopupImageProps>> = ({
  childrenAsProp,
}) => {
  const [open, setOpen] = React.useState(false);
  const [width, setWidth] = React.useState<number>(100);

  return (
    <Box>
      <Dialog
        ref={(node) => {
          setWidth(node?.offsetWidth ?? 0);
        }}
        open={open}
        PaperProps={{
          style: {
            minHeight: "99%",
            minWidth: "99%",
          },
        }}
      >
        <DialogContent
          sx={{ margin: 0, padding: 0, cursor: "pointer" }}
          onClick={() => {
            setOpen(false);
          }}
        >
          {childrenAsProp && React.cloneElement(childrenAsProp, { width })}
        </DialogContent>
      </Dialog>
      <Box
        sx={{ cursor: "pointer" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {childrenAsProp}
      </Box>
    </Box>
  );
};

export type ImageProps = { id: string; type: IMAGE_TYPES; width?: number; title?: string };
export const ImageView: React.FC<ImageProps> = ({ id, type, width: defaultWidth, title }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [width, setWidth] = React.useState<number>(defaultWidth ?? 0);

  React.useEffect(() => {
    const { current } = ref;
    const fn = () => {
      setWidth(current?.offsetWidth ?? 0);
    };

    window.addEventListener("resize", fn);
    fn();

    return () => {
      window.removeEventListener("resize", fn);
    };
  }, []);

  const { data, isFetching, error } = useQuery({
    queryFn: () => downloadImage({ id: id as string, type }),
    queryKey: ["download-image", { id, type }],
    staleTime: SECONDS_30,
    enabled: !!id,
  });

  let content = null;
  if (isFetching) {
    content = <LoadingImage showTitle={!!title} />;
  } else if (data) {
    content = <PopupImage childrenAsProp={<ImageReader data={data} width={width} />} />;
  } else {
    content = <>{String(error)}</>;
  }

  return (
    <div className="image" ref={ref}>
      {title && <Typography variant="overline">{title}</Typography>}
      {content}
    </div>
  );
};
